<template>
    <router-link class="" :to="to">
        <div class="menu-item-container relative flex items-center p-2 rounded-lg mb-2 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 transition">
            <slot name="icon" />
            <div class="menu-item w-full ml-3 text-white">
                <slot name="label" />
            </div>
        </div>
    </router-link>
</template>
<script>
export default {
    props: {
        to: {
            type: String,
            default: "",
        },
    },
};
</script>
<style>
.collapsed .menu-item-container:hover::after {
    content: "";
    display: flex;
    width: 15px;
    height: 40px;
    background: red;
    position: absolute;
    right: -15px;
    top: 0;
    opacity: 0;
}

.collapsed .menu-item-container .menu-item {
    position: absolute;
    display: none;
}

.collapsed .menu-item-container:hover .menu-item {
    display: flex;
    background: #fff;
    width: 200px;
    color: #333;
    padding: 10px 20px;
    border-radius: 5px;
    left: 40px;
    box-shadow: 2px 2px 2px #0000001f;
}
</style>
